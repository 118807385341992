import {
  Directive,
  ElementRef,
  inject,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appHoverGradientGreen]',
  standalone: true
})
export class HoverGradientGreenDirective {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);

  constructor() {
    this.renderer.addClass(this.el.nativeElement, 'main-btn');
    this.renderer.setStyle(this.el.nativeElement, 'transition', 'background-image 0.3s ease-in-out');
    this.renderer.listen(this.el.nativeElement, 'mouseenter', () => {
      if (this.el.nativeElement.disabled) {
        this.renderer.setStyle(this.el.nativeElement, 'backgroundImage', 'none');
        return;
      }
      this.renderer.setStyle(
        this.el.nativeElement,
        'backgroundImage',
        'radial-gradient(circle,rgb(255,169, 102) 0%, rgb(255, 148, 75) 90%)'
      );
    })

    this.renderer.listen(this.el.nativeElement, 'mouseleave', () => {
      this.renderer.setStyle(this.el.nativeElement, 'backgroundImage', 'none');
    });

    this.renderer.listen(this.el.nativeElement, 'mousemove', (e: MouseEvent) => {
      if (this.el.nativeElement.disabled) {
        this.renderer.setStyle(this.el.nativeElement, 'backgroundImage', 'none');
        return;
      }
      const rect = this.el.nativeElement.getBoundingClientRect();
      const x = ((e.clientX - rect.left) * 100) / this.el.nativeElement.clientWidth;
      const y = ((e.clientY - rect.top) * 100) / this.el.nativeElement.clientHeight;
      this.el.nativeElement.style.setProperty('--mouse-x', `${x}`);
      this.el.nativeElement.style.setProperty('--mouse-y', `${y}`);
    });

  }

}
